<template>
  <div>
    <v-container style="padding-top: 4rem">
      <v-col cols="12">
        <div v-if="groupedItems.length === 0">
          <img src="@/assets/nodata.jpg" height="550" width="750" />
        </div>
        <v-card v-else>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Opzoeken"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

          <v-container>
            <v-row v-for="(items, module) in groupedItems" :key="module">
              <v-col cols="12">
                <h5>{{ module }}</h5>
                <v-data-table
                  :headers="headers"
                  :items="items"
                  :search="search"
                  disable-pagination
                  :hide-default-footer="true"
                  class="custom-table-header"
                >
                  <!-- Template voor acties -->
                  <template v-slot:[`item.Actions`]="{ item }">
                    <span style="float:right">
                      <v-btn
                        color="primary"
                        @click="openTemplate(item.id)"
                        text
                      >
                        Template beijken
                      </v-btn>
                    </span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import apiMailTemplate from "../api/apiMailTemplate";

export default {
  data: () => ({
    search: "",

    items: [],

    headers: [
      {
        text: "Template naam",
        value: "templateName",
        sortable: true,
        class: "white-header",
      },

      {
        text: "",
        value: "Actions",
        sortable: false,
        class: "white-header",
      },
    ],
  }),

  async mounted() {
    apiMailTemplate.getMailTemplatas().then((data) => {
      this.items = data;
    });
  },

  computed: {
    groupedItems() {
      return this.items.reduce((group, item) => {
        const { module } = item;
        if (!group[module]) {
          group[module] = [];
        }
        group[module].push(item);
        return group;
      }, {});
    },
  },
  methods: {
    openTemplate(id) {
      this.$router.push(`/MailTemplateDetail/${id}`);
    },
    async createTemplate() {
      try {
        const response = await apiMailTemplate.createMailTemplate(
          this.template
        );
        console.log(response);
        this.$swal.fire("Yes!", "De melding is aangemaakt!", "success");
        await this.fetchData();
        this.resetForm();
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
  },
};
</script>

<style>
.space {
  margin: 3px;
}
.white-header {
  color: white !important;
  background-color: #147d81 !important;
}
</style>
