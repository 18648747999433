import {API} from "./api.base.js";

export default {

  async  createKanban(form) {
    try {
      const response = await API.post('/api/rest/v2/kanban', form);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async  updateKanbanStatus (data) {
    try {
      const response = await API.put('/api/rest/v2/kanban/status', data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  
  async getKanbans() {
    try {
      const response = await API.get('/api/rest/v2/kanban');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteKanban(id) {
    try {
      const response = await API.delete(`/api/rest/v2/kanban/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateKanban(id,data) {
    try {
      const response = await API.put(`/api/rest/v2/kanban/${id}`,data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getSingleKanban(id) {
    try {
      const response = await API.get(`/api/rest/v2/kanban/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
 
};
