import {API} from "./api.base.js"
export default {
  async activateModule(id) {
    try {
      const response = await API.put(`/api/rest/v2/module/status/activate/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deActivatedModule(id) {
    try {
      const response = await API.put(`/api/rest/v2/module/status/deactivate/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getModules() {
    try {
      const response = await API.get('/api/rest/v2/module');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },


};
