<template>
  <v-container>
    <v-card>
      <v-card-title>
        Lead informatie
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-row>
            <v-col cols="6">
              <v-text-field
                dense
                v-model="consult.firstName"
                label="Voornaam"
                outlined
                clearable
              ></v-text-field>
              <v-text-field
                dense
                v-model="consult.lastName"
                label="Achternaam"
                outlined
                clearable
              ></v-text-field>
              <v-text-field
                dense
                disabled
                v-model="consult.createdBy"
                label="Aangemaakt door"
                outlined
                clearable
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                dense
                v-model="consult.email"
                label="E-mail"
                outlined
                clearable
              ></v-text-field>

              <v-text-field
                dense
                v-model="consult.phone"
                label="Telefoonnummer"
                outlined
                clearable
              ></v-text-field>
              <v-select
                dense
                v-model="consult.status"
                :items="[
                  'Aangemaakt',
                  'In Behandeling',
                  'In onderhandeling',
                  'Gesloten',
                ]"
                label="Status"
                outlined
              ></v-select>
            </v-col>
          </v-row>

          <v-textarea
            v-model="consult.notes"
            label="Notities"
            outlined
            clearable
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="red" style="color:white" @click="goBack()" text
            >Annuleer</v-btn
          >
        <v-btn color="#147D81" style="color:white" @click="saveLead()">Opslaan</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="showConvertDialog = true">
          Lead omzetten
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="showConvertDialog" max-width="590">
      <v-card>
        <v-card-title>Kies een optie</v-card-title>
        <v-card-text>
          Wilt u deze lead omzetten in een huurder of relatie?
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="green"
            style="color:white"
            @click="convertLead('huurder')"
            >Huurder</v-btn
          >
          <v-btn
            color="blue"
            style="color:white"
            @click="convertLead('relatie')"
            >Relatie</v-btn
          >
          <v-btn color="red" style="color:white" @click="closeDialog" text
            >Annuleer</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import router from "../../router";
import apiConsult from "../api/apiConsult";
import apiRelation from "../api/apiRelation";
import apiTenant from "../api/apiTenant";
export default {
  data() {
    return {
      consult: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        createdBy: "",
        notes: "",
        status: "Open",
      },
      showConvertDialog: false,
    };
  },
  async mounted() {
   this.getSingleLead();
  },
  methods: {
    getSingleLead(){
      apiConsult.getConsultsbyId(this.$route.params.id).then((data) => {
      this.consult = data;
    });
    },
    goBack(){
      this.$router.push(`/Lead`);
    },
    closeDialog() {
      this.showConvertDialog = false;
    },
    convertLead(type) {
      this.showConvertDialog = false; 

      if (type === "huurder") {
        apiTenant
          .createTenant(this.consult)
          .then(() => {})
          .catch((error) => {
            console.log(error);
          });
      } else if (type === "relatie") {
        apiRelation
          .createRelation(this.consult)
          .then(() => {})
          .catch((error) => {
            console.log(error);
          });
      }
    },
    async saveLead() {
      {
        const id = this.$route.params.id;
        try {
          const response = await apiConsult.updateConsult(id, this.consult);
          console.log(response);
          this.$swal.fire("Yes!", "Het bijwerken is gelukt!", "success");
          this.getSingleLead();
        } catch (error) {
          console.error(error);
          this.$swal.fire(
            "Er is een foutmelding!",
            "Probeer het nogmaals!",
            "error"
          );
        }
      }
    },
  },
};
</script>
