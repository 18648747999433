<template>
  <div class="container mt-5" style="padding-top:4rem">
    <div class="row">
      <AddKanBan @kanban-updated="reloadKanbanData" />
    </div>
    <div class="row mt-5">
      <div class="col-3">
        <div class="p-2 alert alert-secondary">
          <h5>To do</h5>
          <draggable
            class="list-group kanban-column"
            :list="arrBackLog"
            group="tasks"
            @end="onTaskMoved($event.item, 'arrBackLog')"
          >
            <div
              class="list-group-item"
              v-for="element in arrBackLog"
              :key="element.id"
            >
              <b> {{ element.subject }}</b>
              <br />
              <p class="priority">Prioriteit: {{ element.priority }}</p>
              <div>
                <v-btn
                  style="background-color: #147D81;
  color: white;"
                  text
                  @click="openSingle(element.id)"
                >
                  Meer informatie
                </v-btn>
              </div>
            </div>
          </draggable>
        </div>
      </div>

      <div class="col-3">
        <div class="p-2 alert alert-#147D81">
          <h5>In progress</h5>
          <!-- In Progress draggable component. Pass arrInProgress to list prop -->
          <draggable
            class="list-group kanban-column"
            :list="arrInProgress"
            group="tasks"
            @end="onTaskMoved($event.item, 'arrInProgress')"
          >
            <div
              class="list-group-item"
              v-for="element in arrInProgress"
              :key="element.id"
            >
              <b> {{ element.subject }}</b>
              <br />
              <p class="priority">Prioriteit: {{ element.priority }}</p>
              <v-btn class="btn" text @click="openSingle(element.id)">
                Meer informatie
              </v-btn>
            </div>
          </draggable>
        </div>
      </div>

      <div class="col-3">
        <div class="p-2 alert alert-warning">
          <h5>Testing</h5>
          <!-- Testing draggable component. Pass arrTested to list prop -->
          <draggable
            class="list-group kanban-column"
            :list="arrTested"
            group="tasks"
            @end="onTaskMoved($event.item, 'arrTested')"
          >
            <div
              class="list-group-item"
              v-for="element in arrTested"
              :key="element.id"
            >
              <b> {{ element.subject }}</b>
              <br />
              <p class="priority">Prioriteit: {{ element.priority }}</p>
              <v-btn
                style="background-color: #147D81;
  color: white;"
                text
                @click="openSingle(element.id)"
              >
                Meer informatie
              </v-btn>
            </div>
          </draggable>
        </div>
      </div>

      <div class="col-3">
        <div class="p-2 alert alert-success">
          <h5>Done</h5>
          <!-- Done draggable component. Pass arrDone to list prop -->
          <draggable
            class="list-group kanban-column"
            :list="arrDone"
            group="tasks"
            @end="onTaskMoved($event.item, 'arrDone')"
          >
            <div
              class="list-group-item"
              v-for="element in arrDone"
              :key="element.id"
            >
              <b> {{ element.subject }}</b>
              <br />
              <p class="priority">Prioriteit: {{ element.priority }}</p>
              <v-btn
                style="background-color: #147D81;
  color: white;"
                text
                @click="openSingle(element.id)"
              >
                Meer informatie
              </v-btn>
            </div>
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddKanBan from "../kanban/AddKanBan.vue";
import draggable from "vuedraggable";
import apiKanban from "../api/apiKanban";

export default {
  name: "kanban-board",
  components: {
    draggable,
    AddKanBan,
  },
  data() {
    return {
      arrBackLog: [],
      arrInProgress: [],
      arrTested: [],
      arrDone: [],
    };
  },

  async mounted() {
    this.fetchKanbanData();
  },

  methods: {
    reloadKanbanData() {
      this.fetchKanbanData();
    },

    fetchKanbanData() {
      apiKanban.getKanbans().then((data) => {
        this.arrBackLog = data.filter(
          (task) => task.status === "Te Doen (To Do)"
        );
        this.arrInProgress = data.filter(
          (task) => task.status === "In Uitvoering"
        );
        this.arrTested = data.filter((task) => task.status === "Testing");
        this.arrDone = data.filter((task) => task.status === "Done");
      });
    },

    openSingle(id) {
      this.$router.push(`/kanban/details/${id}`);
    },
    onTaskMoved(element, column) {
      let newStatus;
      switch (column) {
        case "arrBackLog":
          newStatus = "Backlog";
          break;
        case "arrInProgress":
          newStatus = "In Progress";
          break;
        case "arrTested":
          newStatus = "Testing";
          break;
        case "arrDone":
          newStatus = "Done";
          break;
        default:
          console.error(`Onbekende kolom: ${column}`);
          return;
      }
      element.status = newStatus;
      this[column].splice(this[column].indexOf(element), 1);

      let newArrayName = `arr${newStatus.replace(/\s/g, "")}`;
      if (this[newArrayName]) {
        this[newArrayName].push(element);
      } else {
        console.error(`Array ${newArrayName} bestaat niet`);
      }
      this.updateKanbanStatus(element.id, newStatus);
    },
    updateKanbanStatus(id, status) {
      apiKanban
        .updateKanban(id, { status: status })
        .then(() => {
          console.log("Task status updated in backend");
        })
        .catch((error) => {
          console.error("Error updating task status:", error);
        });
    },
  },
};
</script>

<style>
.kanban-column {
  min-height: 300px;
}
.priority {
  font-size: 15px;
}
</style>
