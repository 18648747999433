

<template>
    <ViewandEditTemplate />
  </template>
  
  <script>
  import ViewandEditTemplate from "@/components/mailtemplate/ViewandEditTemplate";
  export default {
    data() {
      return {
        data: [],
      };
    },
    components: {
      ViewandEditTemplate,
    },
    metaInfo: {
   title: "FLEX VASTGOED CRM | MailTemplates informatie",
      htmlAttrs: {
        lang: "EN",
        amp: true,
      },
    },
  };
  </script>