<template>
  <v-container style="padding-top:4rem">
    <div>
      <v-row>
        <v-col cols="4">
          <v-text-field
            outlined
            placeholder="Status invullen"
            v-model="form.name"
          ></v-text-field>

          <v-btn
            rounded
            style="background-color: #147D81;
  color: white;"
            @click="addStatus()"
          >
            Status aanmaken
          </v-btn>
        </v-col>

        <v-col cols="8">
          <v-card>
            <v-card-title>
              Status
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Opzoeken"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <div class="table-container">
              <v-data-table
                :headers="headers"
                :items="statussen"
                :search="search"
                disable-pagination
                :hide-default-footer="true"
                class="elevation-1"
              >
                <template v-slot:[`item.Actions`]="{ item }">
                  <v-icon small color="red" @click="deleteStatus(item.id)">
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import apiKanbanStatus from "../api/apiKanbanStatus";

export default {
  data: () => ({
    form: {
      name: "",
      description: "",
    },
    Prioriteiten: ["P 1", "P2", "P 3", "P4"],
    statussen: [],

    search: "",
    headers: [
      {
        text: "Naam",
        value: "name",
        sortable: true,
      },

      {
        text: "Acties",
        value: "Actions",
        sortable: false,
      },
    ],
  }),

  async mounted() {
    this.getKanBanStatus();
  },

  methods: {
    getKanBanStatus() {
      apiKanbanStatus.getKanbansStatus().then((data) => {
        this.statussen = data;
      });
    },
    resetForm() {
      this.form.name = "";
    },

    async addStatus() {
      try {
        if (!this.form.name) {
          this.$swal.fire("Foutmelding", "Vul het veld in", "error");
          return;
        }

        const response = await apiKanbanStatus.createKanbanStatus(this.form);

        console.log(response);
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
        this.resetForm();
        this.getKanBanStatus();
        const data = await apiKanbanStatus.getKanbansStatus();
        this.status = data;
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },

    async deleteStatus(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze type wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        await apiKanbanStatus.deleteKanbanStatus(id);
        const data = await apiKanbanStatus.getKanbansStatus();
        this.status = data;
        this.$swal.fire(
          "Verwijderd",
          "De Status is succesvol verwijderd",
          "success"
        );
      }
    },
  },
};
</script>

<style></style>
