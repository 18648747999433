<template>
  <div>
    <v-container style="padding-top: 2rem">
      <v-row>
        <v-col>
          <v-dialog
            @close="resetform"
            v-model="dialog"
            persistent
            max-width="500px"
            style="margin-top: 150px"
            :return-value.sync="dialogReturnValue"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dense
                rounded
                style="background-color: #147D81;
    color: white;margin-left:20px"
                dark
                v-bind="attrs"
                v-on="on"
              >
                Lead toevoegen
              </v-btn>
            </template>
            <v-card>
              <v-toolbar
                style="background-color: #147D81;
    color: white;"
                dark
              >
                Lead toevoegen
              </v-toolbar>
              <v-card-text>
                <v-container>
                  <v-row justify-center align-center>
                    <v-col cols="6">
                      <v-text-field
                        dense
                        v-model="consult.firstName"
                        label="Voornaam"
                        outlined
                        clearable
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        dense
                        v-model="consult.lastName"
                        label="Achternaam"
                        outlined
                        clearable
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row justify-center align-center>
                    <v-col cols="6">
                      <v-text-field
                        dense
                        v-model="consult.email"
                        label="E-mail"
                        outlined
                        clearable
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        dense
                        v-model="consult.phone"
                        label="Telefoonummer"
                        outlined
                        clearable
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <label> Heeft de consult betrekking op een woning </label>
                  <v-col cols="12">
                    <v-radio-group v-model="categorieType">
                      <v-radio label="Ja" value="Ja" />
                      <v-radio label="Nee" value="Nee" />
                    </v-radio-group>
                    <v-autocomplete
                      v-if="categorieType === 'Ja'"
                      :items="objects.flat()"
                      label="Huis selecteren"
                      v-model="consult.house"
                      item-text="adres"
                      item-value="id"
                    ></v-autocomplete>
                  </v-col>
                  <v-row justify-center align-center>
                    <v-col cols="12">
                      <v-textarea
                        solo
                        name="input-7-4"
                        label="Notitie"
                        v-model="consult.notes"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red darken-1"
                  style="color:white"
                  size="small"
                  @click="closeDialog()"
                >
                  Afsluiten
                </v-btn>

                <v-btn
                  color="#147D81"
                  style="color:white"
                  @click="consult.id ? updateConsult() : createConsult()"
                >
                  Lead toevoegen
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import apiHouse from "../api/apiHouse";
import apiConsult from "../api/apiConsult";
export default {
  data: () => ({
    consults: [],
    objects: [],
    categorieType: "",
    dialogReturnValue: null,
    search: "",
    model: false,
    dialog: false,
    dialogDelete: false,
    consult: {
      lastName: "",
      firstName: "",
      email: "",
      phone: "",
      house: "",
      notes: "",
    },

    items: [],
  }),

  async mounted() {
    apiConsult.getConsults().then((data) => {
      this.consults = data;
    });
    apiHouse.getHouse().then((data) => {
      this.objects = data;
    });
  },

  methods: {
    async closeDialog() {
      this.dialog = false;
      this.resetform();
    },
    async openDialog(id) {
      try {
        const consult = await apiConsult.getConsultsbyId(id);
        this.dialogTitle = "Consult informatie";
        this.consult = consult;
        this.dialog = true;
      } catch (error) {
        console.error(error);
      }
    },

    resetform() {
      this.consult.firstName = "";
      this.consult.lastName = "";
      this.consult.email = "";
      this.consult.phone = "";
      this.consult.house = "";
      this.consult.notes = "";
    },

    async createConsult() {
      const errors = [];
      if (!this.consult.email) {
        errors.push("Email is verplicht");
      }
      if (!this.consult.phone || this.consult.phone.length < 10) {
        errors.push(
          "Telefoonnummer is verplicht en moet tenminste 10 karakters bevatten"
        );
      }
      if (!this.consult.notes) {
        errors.push("Notitie is verplicht");
      }

      if (errors.length > 0) {
        this.$swal.fire("Er zijn foutmeldingen", errors.join("\n"), "error");
        return;
      }

      try {
        const data = {
          firstName: this.consult.firstName,
          lastName: this.consult.lastName,
          email: this.consult.email,
          phone: this.consult.phone,
          house: this.consult.house,
          notes: this.consult.notes,
        };
        const response = await apiConsult.createConsult(data);
        console.log(response);
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
        this.dialog = false;

        this.resetform();
        await apiConsult.getConsults().then((data) => {
          this.consults = data;
        });
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
  },
};
</script>
