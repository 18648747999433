
<template>
  <Lead />
</template>

<script>
import Lead from "@/components/lead/Lead";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Lead,
  },
  metaInfo: {
 title: "FLEX VASTGOED CRM | Lead",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>