<template>
  <v-container style="padding-top:4rem">
    <v-row>
      
        <v-col cols="6">
          <v-text-field v-model="form.description" outlined></v-text-field>
                   <v-textarea v-model="form.description" outlined></v-textarea>
        </v-col>


        <v-col cols="6">
          <v-select  :items="employeeNames"
                    label="Medewerkers"
                    v-model="form.employee"
                    item-value="id" ></v-select>
          <v-select v-model="form.status" :items="statussen" label="Status" item-text="name"></v-select>
            <v-select v-model="form.priority" :items="Prioriteiten" label="Prioriteit"></v-select>
        </v-col>

    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn style="background-color: #147D81;
  color: white" >Bijwerken</v-btn>
              <v-btn text color="red" @click="goBack()" >Ga terug</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import apiKanban from "../api/apiKanban";
import apiUser from "../api/apiUser";
import apiKanbanStatus from "../api/apiKanbanStatus";
export default {

  data: () => ({
    
   
employeeNames:[],
    form: [],
     prioriteiten: ["1", "2", "3", "4"],
    statussen:[],

  }),
  async mounted() {
   apiUser.getActiveEmployee().then((data) => {
      this.employees = data;
      this.employeeNames = data.map((employee) => {
        return `${employee.firstName} ${employee.lastName}`;
      });
    });
    apiKanban.getSingleKanban(this.$route.params.id).then((data) => {
      this.form = data;
    });
    apiKanbanStatus.getKanbansStatus().then((data) => {
      this.statussen = data;
    });
  },
 

  methods: {

     goBack() {
      this.$router.push("../../Scrumboard");
    },
  },
};
</script>

<style scoped></style>
