<template>
  <div class="bg-gray-800 text-white">
    <!-- Andere code hier -->
    <v-snackbar v-model="errorSnackbar" color="error" top>
      {{ errorMessage }}
      <v-btn text @click="errorSnackbar = false">Sluiten</v-btn>
    </v-snackbar>
    <div class="search">
      <v-text-field
        v-model="search"
        label="Opzoeken"
        prepend-icon="mdi-magnify"
        class="mx-4"
        clearable
        solo-inverted
        hide-details
        :outlined="false"
        :dense="true"
        :rounded="true"
        :placeholder="'Opzoeken ' + cards.length + ' Modules'"
      />
    </div>
    <v-container>
      <v-row>
        <v-col
          v-for="(card, index) in filteredCards"
          :key="index"
          cols="12"
          md="3"
        >
          <v-card max-width="250">
            <!-- <v-img :src="card.imageSrc" height="200px" contain></v-img> -->
            <v-img
              :src="
                card.images[0]
                  ? `https://backend.flexvastgoedcrm.nl/static/${card.images[0].filename}`
                  : require('@/assets/logo.png')
              "
              height="200px"
              contain
            ></v-img>
            <v-card-title>
              {{ card.title }}
            </v-card-title>

            <v-card-subtitle>
              {{ card.subtitle }}
            </v-card-subtitle>

            <v-card-actions>
              <v-btn
                block
                v-if="!card.status"
                style="background-color:#2196F3;color:white"
                @click="activateCard(card)"
              >
                Activeren
              </v-btn>
              <v-btn
                block
                v-else
                style="background-color:red;color:white"
                @click="deActivateCard(card)"
              >
                Deactiveren
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import eventBus from "../api/eventBus";
import apiModule from "../api/apiModule";
export default {
  data() {
    return {
      search: "",
      cards: [],
      errorSnackbar: false,
      errorMessage: "",
    };
  },
  async mounted() {
    this.loadModules();
  },

  computed: {
    filteredCards() {
      if (!Array.isArray(this.cards)) {
        return [];
      }
      return this.cards.filter((card) => {
        return card.title.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },

  methods: {
    loadModules() {
      apiModule
        .getModules()
        .then((data) => {
          this.cards = data;
        })
        .catch((error) => {
          console.error("Failed to load modules:", error);
          // Behandel de fout afhankelijk van je vereisten
        });
    },
    activateCard(card) {
      apiModule
        .activateModule(card.id)
        .then(() => {
          this.loadModules();
             eventBus.$emit("modules-updated");
          console.log("Card activated:", card);
        })
        .catch((error) => {
          console.error("Failed to activate card:", error);
          this.errorMessage = "Fout bij het activeren van de kaart.";
          this.errorSnackbar = true;
        });
    },
    deActivateCard(card) {
      apiModule
        .deActivatedModule(card.id)
        .then(() => {
          this.loadModules();
             eventBus.$emit("modules-updated");
          console.log("Card activated:", card);
        })
        .catch((error) => {
          console.error("Failed to activate card:", error);
          this.errorMessage = "Fout bij het activeren van de kaart.";
          this.errorSnackbar = true;
        });
    },
  },
};
</script>

<style scoped>
.search {
  margin-bottom: 10px;
  margin-top: 40px;
}
</style>
