import {API} from "./api.base.js"

export default {

  async  createKanbanStatus(data) {
    try {
      const response = await API.post('/api/rest/v2/kanbanitem/status/', data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async  updateKanbanStatus (data) {
    try {
      const response = await API.put('/api/rest/v2/kanbanitem/status/', data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  
  async getKanbansStatus() {
    try {
      const response = await API.get('/api/rest/v2/kanbanitem/status/');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteKanbanStatus(id) {
    try {
      const response = await API.delete(`/api/rest/v2/kanbanitem/status/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  
  async getSingleKanbanStatus(id) {
    try {
      const response = await API.get(`/api/rest/v2/kanbanitem/status/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
 
};
