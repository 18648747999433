<template>
  <div>
    <v-container style="padding-top: 2rem">
      <v-row>
        <AddLead />
        <v-col cols="12">
          <v-container>
            <v-card>
              <v-card-title>
                Leads
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Opzoeken"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                class="custom-table-header"
                :headers="headers"
                :items="leads"
                :search="search"
                disable-pagination
                :hide-default-footer="true"
              >
                <template v-slot:[`item.Actions`]="{ item }">
                  <v-row>
                    <v-icon small color="green" @click="openLeadDetails(item.id)" >
                      mdi-eye
                    </v-icon>
                    <v-icon small color="red" @click="deleteLead(item.id)">
                      mdi-delete
                    </v-icon>
                  </v-row>
                </template>
              </v-data-table>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AddLead from "./AddLead.vue";
import apiConsult from "../api/apiConsult";
export default {
  components: {
    AddLead,
  },
  data: () => ({
    search: "",
    leads: [],
    items: [],
    headers: [
      {
        text: "Voornaam",
        value: "firstName",
        sortable: true,
        class: "white-header",
      },

      {
        text: "Achternaam",
        value: "lastName",
        sortable: true,
        class: "white-header",
      },
      {
        text: "Email",
        value: "email",
        sortable: true,
        class: "white-header",
      },

      {
        text: "Telefoonnummer",
        value: "phone",
        sortable: true,
        class: "white-header",
      },

      {
        text: "Status",
        value: "status",
        sortable: true,
        class: "white-header",
      },

      {
        text: "Woning",
        value: "propertys[0].adres",
        sortable: true,
        class: "white-header",
      },

      {
        text: "Aangemaakt op:",
        value: "createDate",
        sortable: true,
        class: "white-header",
      },
      {
        text: "Acties",
        value: "Actions",
        sortable: false,
        class: "white-header",
      },
    ],
  }),

  async mounted() {
    apiConsult.getConsults().then((data) => {
      this.leads = data;
    });
  },
  methods: {
    openLeadDetails(id) {
      this.$router.push(`/LeadDetails/${id}`);
    },
    
    async deleteLead(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze lead wil verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        await apiConsult.deleteConsultsbyId(id);
        this.$swal.fire(
          "Verwijderd",
          "De consult is succesvol verwijderd",
          "success"
        );
        await apiConsult.getConsults().then((data) => {
          this.consults = data;
        });
      }
    },
  },
};
</script>

<style>
.custom-table-header .v-data-table-header {
  background-color: #147d81;
  color: white !important;
}
.white-header {
  color: white !important; /* This will change the text color to white */
}
</style>
