
<template>
  <KanBan />
</template>

<script>
import KanBan from "@/components/kanban/KanBan";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    KanBan,
  },
  metaInfo: {
    title: "Scrumboard | Dashboard",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>