<template>
  <v-container>
    <v-row>
      <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            rounded
            style="background-color: #147D81;
  color: white;margin-left:2opx"
            dark
            v-bind="attrs"
            v-on="on"
          >
            Taak aanmaken
          </v-btn>
        </template>
        <v-card>
          <v-toolbar class="btn" dark>Taak toevoegen</v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    name="input-7-1"
                    label="Onderwerp"
                    v-model="form.subject"
                    value=""
                    hint="Hint text"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="employeeNames"
                    label="Medewerkers"
                    v-model="form.employee"
                    item-value="id"
                    multiple
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-select
                    v-model="form.status"
                    :items="statussen"
                    label="Status"
                    item-text="name"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="12">
                  <v-select
                    v-model="form.priority"
                    :items="prioriteiten"
                    label="Prioriteit"
                  ></v-select>
                </v-col>
                <v-col>
                  <label> Heeft de taak een deadline</label>
                  <v-radio-group v-model="categorieType">
                    <v-radio label="Ja" value="Ja" />
                    <v-radio label="Nee" value="Nee" />
                  </v-radio-group>

                  <v-menu
                    v-if="categorieType === 'Ja'"
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.dateFormatted"
                        label="Date"
                        hint="MM/DD/YYYY format"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        @blur="date = parseDate(dateFormatted)"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      no-title
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    name="input-7-1"
                    label="Omschrijving"
                    v-model="form.description"
                    value=""
                    hint="Hint text"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="dialog = false">
              Asluiten
            </v-btn>
            <v-btn class="btn" @click="createKanban()">
              Aanmaken
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import apiKanban from "../api/apiKanban";
import apiUser from "../api/apiUser";
import apiKanbanStatus from "../api/apiKanbanStatus";
export default {
  name: "kanban-board",

  data(vm) {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateFormatted: vm.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),
      statussen: [],
      prioriteiten: ["1", "2", "3", "4"],
      form: {
        status: "",
        description: "",
        subject: "",
        priority: "",
      },
      menu1: false,
      categorieType: "",
      dialog: false,
      employees: [],
      employee: "",
      employeeNames: [],
    };
  },

  async mounted() {
    apiUser.getActiveEmployee().then((data) => {
      this.employees = data;
      this.employeeNames = data.map((employee) => {
        return `${employee.firstName} ${employee.lastName}`;
      });
    });
    apiKanbanStatus.getKanbansStatus().then((data) => {
      this.statussen = data;
    });
  },

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },

  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  methods: {
    async createKanban() {
      try {
        const response = await apiKanban.createKanban(this.form);
        console.log(response);

        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
        this.form = "";
        this.$emit("kanban-updated");
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>

<style>
.kanban-column {
  min-height: 300px;
}
</style>
